<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-8 ps-lg-5">
        <h1><span class="fw-bold"> Bonusfunktion</span> einstellen</h1>
        <div class="alert alert-danger" role="alert">
          Bitte beachten Sie, dass die Aktivierung und Änderungen/Anpassungen Ihres Bonus bis zu 3 Werktage (Mo-Fr) beanspruchen kann!
        </div>
        <div class="pt-2 border-top">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-check">
                <input v-model="bonusData.bonusActive" class="form-check-input" type="checkbox" id="bonusActive">
                <label class="form-check-label" for="bonusActive">
                  Die Funktion Einkaufsbonus aktivieren (Bonus aktiv)
                </label>
              </div>
              <div id="bonusActiveNote" class="form-text error-note"></div>
              <div class="pt-2 border-bottom pb-3" v-if="bonusData.bonusActive === true">
                <div class="row mt-3">
                  <div class="col-lg-7 offset-1">
                    <div class="form-check">
                      <input v-model="bonusData.permanentBonus" class="form-check-input" type="checkbox" value="" id="permanentBonus">
                      <label class="form-check-label" for="permanentBonus">
                        Dauerbonus
                      </label>
                      <div id="permanentBonusNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="row">
                    <div class="col-lg-7 offset-2">
                      <label for="permanentBonusAdditionalInfo" class="form-label label-view">Ggfs. weitere Infos zum Dauerbonus angeben</label>
                      <input v-model="bonusData.permanentBonusAdditionalInfo" type="text" id="permanentBonusAdditionalInfo" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div id="permanentBonusAdditionalInfoNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row mt-2 pt-1 border-top">
                    <div class="col-lg-10 offset-2">
                      <label for="permanentBonusType" class="form-label label-view">Art des Bonus</label>
                      <select v-model="bonusData.permanentBonusType" class="form-select input-view" id="permanentBonusType" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                        <option v-for="permanentBonusType in permanentBonusTypes" :key="permanentBonusType" :value="permanentBonusType">{{permanentBonusType}}</option>
                      </select>
                      <div id="permanentBonusTypeNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.permanentBonusType == 'Prozentualer Bonus vom Einkaufswert' || bonusData.permanentBonusType == 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz'">
                    <div class="col-lg-7 offset-2">
                      <label for="permanentBonusValueInPercent" class="form-label label-view">Bonuswert in %</label>
                      <input v-model="bonusData.permanentBonusValueInPercent" type="number" id="permanentBonusValueInPercent" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div id="permanentBonusValueInPercentNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.permanentBonusType == 'Festbetrag in Kombination mit einem Mindestumsatz' || bonusData.permanentBonusType == 'Festbetrag'">
                    <div class="col-lg-7 offset-2">
                      <label for="permanentBonusValueInEuro" class="form-label label-view">Bonuswert in €</label>
                      <input v-model="bonusData.permanentBonusValueInEuro" type="text" id="permanentBonusValueInEuro" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div id="permanentBonusValueInEuroNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.permanentBonusType == 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz'">
                    <div class="col-lg-7 offset-2">
                      <label for="permanentBonusPercentFromMinimum" class="form-label label-view">Ab Mindestumsatz in €</label>
                      <input v-model="bonusData.permanentBonusPercentFromMinimum" type="text" id="permanentBonusPercentFromMinimum" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div id="permanentBonusPercentFromMinimumNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.permanentBonusType == 'Festbetrag in Kombination mit einem Mindestumsatz'">
                    <div class="col-lg-7 offset-2">
                      <label for="permanentBonusEuroFromMinimum" class="form-label label-view">Ab Mindestumsatz in €</label>
                      <input v-model="bonusData.permanentBonusEuroFromMinimum" type="text" id="permanentBonusEuroFromMinimum" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div id="permanentBonusEuroFromMinimumNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row mt-2 pt-1 border-top">
                    <div class="col-lg-7 offset-2">
                      <label for="permanentBonusForCompletePurchase" class="form-label label-view">Bonus wird auf den gesamten Einkauf vergeben</label>
                      <select v-model="bonusData.permanentBonusForCompletePurchase" class="form-select input-view" id="permanentBonusForCompletePurchase" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                        <option value="Ja">Ja</option>
                        <option value="Nein">Nein</option>
                      </select>
                      <div id="permanentBonusForCompletePurchaseNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-7 offset-2" v-if="bonusData.permanentBonusForCompletePurchase === 'Nein'">
                      <label for="permanentBonusExceptOrOnly" class="form-label label-view">Außer auf... / Nur auf...</label>
                      <select v-model="bonusData.permanentBonusExceptOrOnly" class="form-select input-view" id="permanentBonusExceptOrOnly" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                        <option value="except">Außer auf...</option>
                        <option value="only">Nur auf...</option>
                      </select>
                      <div id="permanentBonusExceptOrOnlyNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-7 offset-2" v-if="bonusData.permanentBonusExceptOrOnly === 'except'">
                      <label for="permanentBonusExceptText" class="form-label label-view">Außer auf... (bitte Details angeben)</label>
                      <input v-model="bonusData.permanentBonusExceptText" type="text" maxlength="100" id="permanentBonusExceptText" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div id="permanentBonusExceptTextNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-7 offset-2" v-if="bonusData.permanentBonusExceptOrOnly === 'only'">
                      <label for="permanentBonusOnlyText" class="form-label label-view">Nur auf... (bitte Details angeben)</label>
                      <input v-model="bonusData.permanentBonusOnlyText" type="text"  maxlength="100" id="permanentBonusOnlyText" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div id="permanentBonusOnlyTextNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row mt-2 pt-1 border-top">
                    <div class="col-lg-7 offset-2">
                      <label for="permanentBonusTiming" class="form-label label-view">Bonus mit Zeitsteuerung</label>
                      <select v-model="bonusData.permanentBonusTiming" class="form-select input-view" id="permanentBonusTiming" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                        <option value="Ja">Ja</option>
                        <option value="Nein">Nein</option>
                      </select>
                      <div id="permanentBonusTimingNote" class="form-text error-note"></div>
                      <label v-if="bonusData.permanentBonusTiming == 'Ja'" for="permanentBonusTimingDetails" class="form-label label-view">In der Zeit von...</label>
                      <input v-if="bonusData.permanentBonusTiming == 'Ja'" v-model="bonusData.permanentBonusTimingDetails" type="text" id="permanentBonusTimingDetails" class="form-control input-view" :disabled="!bonusData.permanentBonus" :readonly="!bonusData.permanentBonus">
                      <div v-if="bonusData.permanentBonusTiming == 'Ja'" id="permanentBonusTimingDetailsNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 border-top" v-if="bonusData.bonusActive === true">
                  <div class="col-lg-7 offset-1">
                    <div class="form-check">
                      <input v-model="bonusData.actionBonus" class="form-check-input" type="checkbox" value="" id="actionBonus">
                      <label class="form-check-label" for="actionBonus">
                        Aktionsbonus (zeitlich befristet) <img class="img-fluid" :src="require('../assets/img/booking/circle-info.svg')" width="20" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Bitte beachten Sie, dass je nach Ausgestaltung ein Aktionsbonus unter Umständen nicht parallel zu einem Dauerbonus aktiviert sein kann. Für konkrete Fragen dazu wenden Sie sich bitte an den Support.">
                      </label>
                      <div id="actionBonusNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="row">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusAdditionalInfo" class="form-label label-view">Ggfs. weitere Infos zum Aktionsbonus angeben, z.B. Tag der offenen Tür o.ä.</label>
                      <input v-model="bonusData.actionBonusAdditionalInfo" type="text" id="actionBonusAdditionalInfo" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusAdditionalInfoNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusStartDate" class="form-label label-view">Startdatum des Aktionsbonus</label>
                      <input v-model="bonusData.actionBonusStartDate" type="date" id="actionBonusStartDate" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusStartDateNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusEndDate" class="form-label label-view">Enddatum des Aktionsbonus</label>
                      <input v-model="bonusData.actionBonusEndDate" type="date" id="actionBonusEndDate" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusEndDateNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row mt-2 pt-1 border-top">
                    <div class="col-lg-10 offset-2">
                      <label for="actionBonusType" class="form-label label-view">Art des Bonus</label>
                      <select v-model="bonusData.actionBonusType" class="form-select input-view" id="actionBonusType" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                        <option v-for="actionBonusType in actionBonusTypes" :key="actionBonusType" :value="actionBonusType">{{actionBonusType}}</option>
                      </select>
                      <div id="actionBonusTypeNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.actionBonusType == 'Prozentualer Bonus vom Einkaufswert' || bonusData.actionBonusType == 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz'">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusValueInPercent" class="form-label label-view">Bonuswert in %</label>
                      <input v-model="bonusData.actionBonusValueInPercent" type="number" id="actionBonusValueInPercent" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusValueInPercentNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.actionBonusType == 'Festbetrag in Kombination mit einem Mindestumsatz' || bonusData.actionBonusType == 'Festbetrag'">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusValueInEuro" class="form-label label-view">Bonuswert in €</label>
                      <input v-model="bonusData.actionBonusValueInEuro" type="text" id="actionBonusValueInEuro" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusValueInEuroNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.actionBonusType == 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz'">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusPercentFromMinimum" class="form-label label-view">Ab Mindestumsatz in €</label>
                      <input v-model="bonusData.actionBonusPercentFromMinimum" type="text" id="actionBonusPercentFromMinimum" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusPercentFromMinimumNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row" v-if="bonusData.actionBonusType == 'Festbetrag in Kombination mit einem Mindestumsatz'">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusEuroFromMinimum" class="form-label label-view">Ab Mindestumsatz in €</label>
                      <input v-model="bonusData.actionBonusEuroFromMinimum" type="text" id="actionBonusEuroFromMinimum" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusEuroFromMinimumNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row mt-2 pt-1 border-top">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusForCompletePurchase" class="form-label label-view">Bonus wird auf den gesamten Einkauf vergeben</label>
                      <select v-model="bonusData.actionBonusForCompletePurchase" class="form-select input-view" id="actionBonusForCompletePurchase" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                        <option value="Ja">Ja</option>
                        <option value="Nein">Nein</option>
                      </select>
                      <div id="actionBonusForCompletePurchaseNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-7 offset-2" v-if="bonusData.actionBonusForCompletePurchase === 'Nein'">
                      <label for="actionBonusExceptOrOnly" class="form-label label-view">Außer auf... / Nur auf...</label>
                      <select v-model="bonusData.actionBonusExceptOrOnly" class="form-select input-view" id="actionBonusExceptOrOnly" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                        <option value="except">Außer auf...</option>
                        <option value="only">Nur auf...</option>
                      </select>
                      <div id="actionBonusExceptOrOnlyNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-7 offset-2" v-if="bonusData.actionBonusExceptOrOnly === 'except'">
                      <label for="actionBonusExceptText" class="form-label label-view">Außer auf... (bitte Details angeben)</label>
                      <input v-model="bonusData.actionBonusExceptText" type="text"  maxlength="100" id="actionBonusExceptText" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusExceptTextNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-7 offset-2" v-if="bonusData.actionBonusExceptOrOnly === 'only'">
                      <label for="actionBonusOnlyText" class="form-label label-view">Nur auf... (bitte Details angeben)</label>
                      <input v-model="bonusData.actionBonusOnlyText" type="text"  maxlength="100" id="actionBonusOnlyText" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div id="actionBonusOnlyTextNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row mt-2 pt-1 border-top">
                    <div class="col-lg-7 offset-2">
                      <label for="actionBonusTiming" class="form-label label-view">Bonus mit Zeitsteuerung</label>
                      <select v-model="bonusData.actionBonusTiming" class="form-select input-view" id="actionBonusTiming" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                        <option value="Ja">Ja</option>
                        <option value="Nein">Nein</option>
                      </select>
                      <div id="actionBonusTimingNote" class="form-text error-note"></div>
                      <label v-if="bonusData.actionBonusTiming == 'Ja'" for="actionBonusTimingDetails" class="form-label label-view">In der Zeit von...</label>
                      <input v-if="bonusData.actionBonusTiming == 'Ja'" v-model="bonusData.actionBonusTimingDetails" type="text" id="actionBonusTimingDetails" class="form-control input-view" :disabled="!bonusData.actionBonus" :readonly="!bonusData.actionBonus">
                      <div v-if="bonusData.actionBonusTiming == 'Ja'" id="actionBonusTimingDetailsNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-3 border-top" v-if="bonusData.bonusActive === true && (bonusData.community === 'Lahr' || bonusData.community === 'Gauting' || bonusData.community === 'Krailing' || bonusData.community === 'Neuried')">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-check">
                <input v-model="bonusData.accepted" class="form-check-input" type="checkbox" id="accepted">
                <label class="form-check-label" for="accepted">
                  Ich habe zur Kenntnis genommen, dass die Gebühr je Bonusbuchung (Eingabe eines Einkaufs) zu Ihrem aktuellen Disagio-Modell verrechnet wird. Berechnungsgrundlage ist dabei jeweils der eingegebene Einkaufsbetrag. Die maximale Gebühr je Bonusbuchung (Einkauf) beträgt aber nur 0,50 €.
                </label>
                <div id="acceptedNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-2 mb-3 border-top">
          <div class="text-end">
            <button @click="saveBonus()" class="btn btn-primary ms-4">Bonusauftrag erteilen >></button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import Helper from '../helper'
import CardData from '@/components/CardData.vue'
const bootstrap = require('bootstrap')

export default {
  name: 'PartnerSetBonusPage',
  components: {
    CardData
  },
  data () {
    return {
      bonusData: {},
      permanentBonusTypes: [],
      actionBonusTypes: [],
      validationStatus: false
    }
  },
  methods: {
    async saveBonus () {
      if (this.validateBonusData()) {
        this.$store.commit('SET_LOADING_STATUS', true)
        await this.$store.dispatch('setBonus', this.bonusData)
          .then(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
          }).catch(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      }
    },
    validateBonusData () {
      this.validationStatus = true

      Object.keys(this.bonusData).forEach(key => {
        if ((this.bonusData.permanentBonus && key.startsWith('permanent')) || (this.bonusData.actionBonus && key.startsWith('action')) || key === 'accepted' || key === 'bonusActive') {
          Helper.removeValidationError(key)
        }
      })

      if (this.bonusData.bonusActive) {
        if (!this.bonusData.accepted && (this.bonusData.community === 'Lahr' || this.bonusData.community === 'Gauting' || this.bonusData.community === 'Krailing' || this.bonusData.community === 'Neuried')) {
          this.showValidationError('accepted', 'Sie müssen diese Bedingungen bestätigen.')
        }

        if (!this.bonusData.permanentBonus && !this.bonusData.actionBonus) {
          this.showValidationError('bonusActive', 'Wenn der Einkaufsbonus aktiviert wurde, muss entweder ein Dauerbonus oder ein Aktionsbonus eingestellt werden.')
        }

        if (this.bonusData.permanentBonus) {
          if (!this.bonusData.permanentBonusType) {
            this.showValidationError('permanentBonusType', 'Das Feld muss ausgewählt werden.')
          }

          if (this.bonusData.permanentBonusType === 'Prozentualer Bonus vom Einkaufswert' || this.bonusData.permanentBonusType === 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz') {
            if (!this.bonusData.permanentBonusValueInPercent) {
              this.showValidationError('permanentBonusValueInPercent', 'Das Feld muss ausgefüllt werden.')
            } else if (!(/^(\d+)(,\d{2}|\.\d{2})?$/.test(this.bonusData.permanentBonusValueInPercent))) {
              this.showValidationError('permanentBonusValueInPercent', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
            } else if (parseInt(this.bonusData.permanentBonusValueInPercent) < 0.01) {
              this.showValidationError('permanentBonusValueInPercent', 'Das Feld darf nur einen positiven Prozentwert enthalten.')
            } else if (parseInt(this.bonusData.permanentBonusValueInPercent) > 100) {
              this.showValidationError('permanentBonusValueInPercent', 'Das Feld darf nur einen Prozentwert von maximal 100 enthalten.')
            }

            if (this.bonusData.permanentBonusType === 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz') {
              if (!this.bonusData.permanentBonusPercentFromMinimum) {
                this.showValidationError('permanentBonusPercentFromMinimum', 'Das Feld muss ausgefüllt werden.')
              } else if (!(/^(\d+)(,\d{2}|\.\d{2})?$/.test(this.bonusData.permanentBonusPercentFromMinimum))) {
                this.showValidationError('permanentBonusPercentFromMinimum', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
              } else if (parseInt(this.bonusData.permanentBonusPercentFromMinimum) < 0) {
                this.showValidationError('permanentBonusPercentFromMinimum', 'Das Feld darf nur einen positiven Eurobetrag enthalten.')
              }
            }
          }

          if (this.bonusData.permanentBonusType === 'Festbetrag in Kombination mit einem Mindestumsatz') {
            if (!this.bonusData.permanentBonusEuroFromMinimum) {
              this.showValidationError('permanentBonusEuroFromMinimum', 'Das Feld muss ausgefüllt werden.')
            } else if (!(/^(\d+)(,\d{2}|\.\d{2})?$/.test(this.bonusData.permanentBonusEuroFromMinimum))) {
              this.showValidationError('permanentBonusEuroFromMinimum', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
            } else if (parseInt(this.bonusData.permanentBonusEuroFromMinimum) < 0) {
              this.showValidationError('permanentBonusEuroFromMinimum', 'Das Feld darf nur einen positiven Eurobetrag enthalten.')
            }
          }

          if (this.bonusData.permanentBonusType === 'Festbetrag in Kombination mit einem Mindestumsatz' || this.bonusData.permanentBonusType === 'Festbetrag') {
            if (!this.bonusData.permanentBonusValueInEuro) {
              this.showValidationError('permanentBonusValueInEuro', 'Das Feld muss ausgefüllt werden.')
            } else if (!(/^(\d+)(,\d{2}|\.\d{2})?$/.test(this.bonusData.permanentBonusValueInEuro))) {
              this.showValidationError('permanentBonusValueInEuro', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
            } else if (parseInt(this.bonusData.permanentBonusValueInEuro) < 0.01) {
              this.showValidationError('permanentBonusValueInEuro', 'Das Feld darf nur einen positiven Eurobetrag enthalten.')
            }
          }

          if (!this.bonusData.permanentBonusForCompletePurchase) {
            this.showValidationError('permanentBonusForCompletePurchase', 'Das Feld muss ausgewählt werden.')
          } else {
            if (this.bonusData.permanentBonusForCompletePurchase === 'Nein') {
              if (!this.bonusData.permanentBonusExceptOrOnly) {
                this.showValidationError('permanentBonusExceptOrOnly', 'Das Feld muss ausgefüllt werden.')
              } else {
                if (this.bonusData.permanentBonusExceptOrOnly === 'except') {
                  if (!this.bonusData.permanentBonusExceptText) {
                    this.showValidationError('permanentBonusExceptText', 'Das Feld muss ausgefüllt werden.')
                  }
                } else if (this.bonusData.permanentBonusExceptOrOnly === 'only') {
                  if (!this.bonusData.permanentBonusOnlyText) {
                    this.showValidationError('permanentBonusOnlyText', 'Das Feld muss ausgefüllt werden.')
                  }
                }
              }
            }
          }

          if (!this.bonusData.permanentBonusTiming) {
            this.showValidationError('permanentBonusTiming', 'Das Feld muss ausgewählt werden.')
          } else {
            if (this.bonusData.permanentBonusTiming === 'Ja') {
              if (!this.bonusData.permanentBonusTimingDetails) {
                this.showValidationError('permanentBonusTimingDetails', 'Das Feld muss ausgefüllt werden.')
              }
            }
          }
        }

        if (this.bonusData.actionBonus) {
          if (!this.bonusData.actionBonusType) {
            this.showValidationError('actionBonusType', 'Das Feld muss ausgewählt werden.')
          }

          if (!this.bonusData.actionBonusStartDate) {
            this.showValidationError('actionBonusStartDate', 'Das Feld muss ausgefüllt werden.')
          } else {
            const isValidStartDate = Date.parse(this.bonusData.actionBonusStartDate)
            if (isNaN(isValidStartDate)) {
              this.showValidationError('actionBonusStartDate', 'Das Datumsformat ist ungültig.')
            }
          }

          if (!this.bonusData.actionBonusEndDate) {
            this.showValidationError('actionBonusEndDate', 'Das Feld muss ausgefüllt werden.')
          } else {
            const isValidEndDate = Date.parse(this.bonusData.actionBonusEndDate)
            if (isNaN(isValidEndDate)) {
              this.showValidationError('actionBonusEndDate', 'Das Datumsformat ist ungültig.')
            }
          }

          if (this.bonusData.actionBonusType === 'Prozentualer Bonus vom Einkaufswert' || this.bonusData.actionBonusType === 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz') {
            if (!this.bonusData.actionBonusValueInPercent) {
              this.showValidationError('actionBonusValueInPercent', 'Das Feld muss ausgefüllt werden.')
            } else if (!(/^(\d+)(,\d{1,2}|\.\d{1,2})?$/.test(this.bonusData.actionBonusValueInPercent))) {
              this.showValidationError('actionBonusValueInPercent', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
            } else if (parseInt(this.bonusData.actionBonusValueInPercent) < 0.01) {
              this.showValidationError('actionBonusValueInPercent', 'Das Feld darf nur einen positiven Prozentwert enthalten.')
            } else if (parseInt(this.bonusData.actionBonusValueInPercent) > 100) {
              this.showValidationError('actionBonusValueInPercent', 'Das Feld darf nur einen Prozentwert von maximal 100 enthalten.')
            }

            if (this.bonusData.actionBonusType === 'Prozentualer Bonus vom Einkaufswert in Kombination mit einem Mindestumsatz') {
              if (!this.bonusData.actionBonusPercentFromMinimum) {
                this.showValidationError('actionBonusPercentFromMinimum', 'Das Feld muss ausgefüllt werden.')
              } else if (!(/^(\d+)(,\d{1,2}|\.\d{1,2})?$/.test(this.bonusData.actionBonusPercentFromMinimum))) {
                this.showValidationError('actionBonusPercentFromMinimum', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
              } else if (parseInt(this.bonusData.actionBonusPercentFromMinimum) < 0) {
                this.showValidationError('actionBonusPercentFromMinimum', 'Das Feld darf nur einen positiven Eurobetrag enthalten.')
              }
            }
          }

          if (this.bonusData.actionBonusType === 'Festbetrag in Kombination mit einem Mindestumsatz') {
            if (!this.bonusData.actionBonusEuroFromMinimum) {
              this.showValidationError('actionBonusEuroFromMinimum', 'Das Feld muss ausgefüllt werden.')
            } else if (!(/^(\d+)(,\d{1,2}|\.\d{1,2})?$/.test(this.bonusData.actionBonusEuroFromMinimum))) {
              this.showValidationError('actionBonusEuroFromMinimum', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
            } else if (parseInt(this.bonusData.actionBonusEuroFromMinimum) < 0) {
              this.showValidationError('actionBonusEuroFromMinimum', 'Das Feld darf nur einen positiven Eurobetrag enthalten.')
            }
          }

          if (this.bonusData.actionBonusType === 'Festbetrag in Kombination mit einem Mindestumsatz' || this.bonusData.actionBonusType === 'Festbetrag') {
            if (!this.bonusData.actionBonusValueInEuro) {
              this.showValidationError('actionBonusValueInEuro', 'Das Feld muss ausgefüllt werden.')
            } else if (!(/^(\d+)(,\d{1,2}|\.\d{1,2})?$/.test(this.bonusData.actionBonusValueInEuro))) {
              this.showValidationError('actionBonusValueInEuro', 'Das Feld darf nur Ziffern enthalten und Dezimalstellen müssen zwei Ziffern enthalten.')
            } else if (parseInt(this.bonusData.actionBonusValueInEuro) < 0.01) {
              this.showValidationError('actionBonusValueInEuro', 'Das Feld darf nur einen positiven Eurobetrag enthalten.')
            }
          }

          if (!this.bonusData.actionBonusForCompletePurchase) {
            this.showValidationError('actionBonusForCompletePurchase', 'Das Feld muss ausgewählt werden.')
          } else {
            if (this.bonusData.actionBonusForCompletePurchase === 'Nein') {
              if (!this.bonusData.actionBonusExceptOrOnly) {
                this.showValidationError('actionBonusExceptOrOnly', 'Das Feld muss ausgefüllt werden.')
              } else {
                if (this.bonusData.actionBonusExceptOrOnly === 'except') {
                  if (!this.bonusData.actionBonusExceptText) {
                    this.showValidationError('actionBonusExceptText', 'Das Feld muss ausgefüllt werden.')
                  }
                } else if (this.bonusData.actionBonusExceptOrOnly === 'only') {
                  if (!this.bonusData.actionBonusOnlyText) {
                    this.showValidationError('actionBonusOnlyText', 'Das Feld muss ausgefüllt werden.')
                  }
                }
              }
            }
          }

          if (!this.bonusData.actionBonusTiming) {
            this.showValidationError('actionBonusTiming', 'Das Feld muss ausgewählt werden.')
          } else {
            if (this.bonusData.actionBonusTiming === 'Ja') {
              if (!this.bonusData.actionBonusTimingDetails) {
                this.showValidationError('actionBonusTimingDetails', 'Das Feld muss ausgefüllt werden.')
              }
            }
          }
        }
      }

      return this.validationStatus
    },
    showValidationError (key, validationMessage) {
      document.getElementById(key).classList.add('form-element-danger')
      document.getElementById(key + 'Note').innerHTML = validationMessage
      this.validationStatus = false
    },
    async fetchBonusData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getBonusData').then(response => {
        this.bonusData = response.bonusData
        this.permanentBonusTypes = response.suggestedValues.permanentBonusTypes
        this.actionBonusTypes = response.suggestedValues.actionBonusTypes
      }).catch(() => {
        this.deactivateForm()
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    },
    renderTooltips () {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })
    }
  },
  created () {
    this.fetchBonusData()
  },
  updated () {
    this.renderTooltips()
    if (this.currentStep === 5 && !this.paypalLoaded) {
      this.showPayPal()
    }
  }
}
</script>
